.blurred {
    filter: blur(3px);
    pointer-events: none; /* Prevent clicking the button */
}


.content {
    position: relative;
    z-index: 2; /* Ensure your content is clickable */
}

.navbar {
    z-index: 1000; /* Higher z-index to stay above Alert */
  }
  
  .alert {
    z-index: 1050; /* Ensure it's above the navbar */
    position: fixed; /* Ensure it stays in place during scrolling */
    top: 10px; /* Adjust the top position if needed */
    right: 10px; /* Adjust the right position if needed */
  }
  .alert {
    position: fixed;
    top: 10px; /* Adjust based on where you want the alert */
    right: 10px;
    z-index: 1050;
  }

  .dot-pulse {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .bounce {
    width: 10px;
    height: 10px;
    margin: 5px;
    background-color: #007bff;
    border-radius: 50%;
    animation: pulse 1.2s infinite ease-in-out;
  }
  
  .bounce:nth-child(2) {
    animation-delay: -0.4s;
  }
  
  .bounce:nth-child(3) {
    animation-delay: -0.2s;
  }
  
  @keyframes pulse {
    0%, 80%, 100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
  
  /* Add this to your CSS file or in a <style> tag */
body, html {
    margin: 0;
    padding: 0;
    height: 100%;
  }
  
  .container {
    min-height: 100vh; /* Ensures the container takes up full height */
  }
  
  .card {
    margin-bottom: 0; /* Ensures no extra space below the card */
  }
  
    
.credits {
  margin-top: 0vh;
}



.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}


.container {
  margin-top: 50px;
}

.img-fluid {
  max-height: 400px;
  object-fit: cover;
}

h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

p {
  font-size: 1.1rem;
  line-height: 1.6;
}

