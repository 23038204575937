.about-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #f5f5f5;
    padding: 2rem;
}

.about-content {
    max-width: 800px;
    background: #ffffff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.about-title {
    font-size: 2.5rem;
    color: #007bff;
    margin-bottom: 1rem;
}

.about-description {
    font-size: 1.2rem;
    color: #333;
    line-height: 1.6;
}

.highlight {
    color: #007bff;
    font-weight: bold;
}

.about-features {
    margin-top: 2rem;
}

.features-title {
    font-size: 2rem;
    color: #007bff;
    margin-bottom: 1rem;
}

.features-list {
    list-style: none;
    padding: 0;
}

.features-list li {
    font-size: 1.1rem;
    color: #555;
    margin-bottom: 0.5rem;
    line-height: 1.5;
}

.about-footer {
    margin-top: 2rem;
    font-size: 1rem;
    color: #777;
}
